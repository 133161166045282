import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import SocialMediaLinks from "./Content/SocialMediaLinks";
import PageLinks from "./Content/PageLinks";
import { CNBlue } from "./Content/SiteColors";
import LogoWithWordMarkImage from "../assets/img/logo-with-wordmark/logo-with-wordmark.png";


const Footer = () => {
	return (
		<div id="cn-footer">
			<Container
				fluid
				className="m-0 p-0"
				style={{overflowX: "hidden"}}
			>
				<PageLinks
					className="m-0 p-md-4"
					style={styles.pageLinks}
				/>
				<div
					style={styles.image}
					data-aos="fade-zoom-in"
					data-aos-easing="ease-in-back"
					data-aos-duration="1000"
					data-aos-delay="250"
					data-aos-anchor-placement="top-bottom"
					data-aos-anchor="#cn-footer"

				>
					<Image
						src={LogoWithWordMarkImage}
						width="60px;"
						id="cn-footer-1"
					/>
				</div>
				<div
					style={styles.image}
					data-aos="fade-zoom-in"
					data-aos-easing="ease-in-back"
					data-aos-duration="2000"
					data-aos-delay="500"
					data-aos-anchor-placement="top-bottom"
					data-aos-anchor="#cn-footer"
				>
					<SocialMediaLinks />
				</div>
				<div
					style={styles.copyright}
					data-aos="fade-zoom-in"
					data-aos-easing="ease-in-back"
					data-aos-duration="2500"
					data-aos-delay="1000"
					data-aos-anchor-placement="top-bottom"
					data-aos-anchor="#cn-footer"
				>
					Copyright © Combat Junkies Innovations LLC 2024 All rights reserved
				</div>

				<div style={styles.header}></div>
			</Container>
		</div>
	);
};


const styles = {
	header: {
		color: "white",
		backgroundColor: CNBlue,
		minHeight: "30px",
		marginTop: 0,
		// padding: 9,
		paddingTop: 9,
		paddingBottom: 9,
		fontSize: 'xx-small',
		textAlign: 'left',
	},
	image: {
		margin: 'auto',
		alignItems: 'center',
		paddingTop: 15,
		// paddingBottom: 15,
	},
	text: {

	},
	copyright: {
		margin: 'auto',
		alignItems: 'center',
		paddingTop: 15,
		paddingBottom: 15,
		fontSize: 12,
	},
	footer: {
		marginBottom: 0,
		color: "white",
		backgroundColor: CNBlue,
		minHeight: "30px",
		marginTop: 0,
		// padding: 9,
		paddingTop: 9,
		paddingBottom: 9,
		fontSize: 'xx-small',
		textAlign: 'left',
	},
	pageLinks: {
		backgroundColor: CNBlue,
		color: 'white',
		paddingTop: 15,
		paddingBottom: 15,
	}
};


export default Footer;

