import React, { Component } from "react";
import "./GoogleAppStore.css";
import Content, { H1, H2, P, UL, OL, LI } from "../../Components/Content/Content";
import ModalVideo from "react-modal-video";
import ColoredButton from "../../Components/Buttons/ColoredButton";


class GoogleAppStore extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal () {
		this.setState({isOpen: true})
	}

	render() {
		return (
			<div className="px-sm-1 px-md-5 py-md-4">
				<Content style={styles.pageContent}>
					<H1>Join the TacAO Beta Testers Program</H1>

					<H2>Important Update for Android Users</H2>
					<P>Due to recent changes in Google Play Store requirements, developers must have at least 20 beta testers actively testing their app for a minimum of 14 days before the app can be released to the public. To comply with this new requirement, we need your help to bring TacAO to the Google Play Store!</P>

					<P>
						Watch the 2 easy steps to download TacAO on Google Android.
					</P>
					<P
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					>
						<React.Fragment>
							<ModalVideo
								channel='youtube'
								isOpen={this.state.isOpen}
								videoId='pS34MaX8Nsc'
								onClose={() => this.setState({isOpen: false})}
							/>
							<ColoredButton
								style={{
									borderRadius: '5px!important',
								}}
								onClick={() => {
									this.setState({isOpen: true});
								}
								}>
								Watch Now
							</ColoredButton>
						</React.Fragment>
					</P>

					<H2>How to Join the TacAO Beta Testers Program</H2>
					<P>If you're an Android user and would like to help us by participating in the TacAO beta testing, please follow these simple steps:
						<OL>
							<LI>
								<strong>Join the TacAO Beta Testers Google Group</strong>
								<UL>
									<LI>Navigate to the <a href="https://groups.google.com/g/tacao-beta-testers/" target="_blank">TacAO Beta Testers Google Group page</a></LI>
									<LI>Click the button labeled "Ask to join group" in the top-left of the page</LI>
								</UL>
							</LI>
							<LI>
								<strong>Download the TacAO Beta Version</strong>
								<UL>
									<LI>Once you've joined the Google Group, you can download the beta version of TacAO from Google Play <a href="https://play.google.com/apps/testing/com.combatjunkiesinnovations.tacao" target="_blank">here</a>
									</LI>
								</UL>
							</LI>
						</OL>
					</P>

					<H2>Why Your Participation Matters</H2>
					<P>
						By joining our beta testers program, you'll be directly contributing to the success of TacAO on the Google Play Store. Your feedback and active participation will help us refine the app, fix any issues, and ensure a smooth launch for all Android users.
					</P>

					<H2>Benefits of Being a Beta Tester</H2>
					<P>
						<UL>
							<LI>Get early access to the latest features and updates</LI>
							<LI>Provide valuable feedback to help improve the app</LI>
							<LI>Be a part of a community that is passionate about enhancing the airsoft gaming experience</LI>
						</UL>
					</P>

					<P>Thank you for your support and for helping us make TacAO the best it can be!</P>

				</Content>
			</div>
		);
	}
}


const styles = {
	pageContent: {
		color: '#2A7C6F',
		textAlign: 'left',
		justifyContent: 'left',
	},
};

export default GoogleAppStore;


