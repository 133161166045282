import React, { Component } from "react";

class ColoredButton extends Component {
	constructor(props) {
		super(props);
		let onClick = props?.onClick ? props.onClick : ()=>{};
		this.state = {
			style: {...styles.button, ...props.style},
			onClick: onClick,
		};
	}

	render() {
		return (
			<button
				style={this.state.style}
				onClick={this.state.onClick}
			>
				{this.props.children}
			</button>
		);
	}
}

const styles = {
	button: {
		backgroundColor: '#2A7C6F',
		color: 'white',
		border: "none",
		padding: "11px 24px",
		textAlign: "center",
		textDecoration: "none",
		display: "inline-block",
		fontSize: "18px",
		fontWeight: "lighter",
	},
	centered: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
};

export default ColoredButton;

