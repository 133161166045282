/**
 * Build the BASE URL for the REST API
 *
 * @returns {string}
 */
function getRestApiUrl() {
	console.debug(`---> process.env.NODE_ENV = ${process.env.NODE_ENV}`);
	let REST_API_URL;
	if (process.env.NODE_ENV === 'jonin') {
		REST_API_URL = 'https://api-jonin.tacticalao.com/api';
	} else if (process.env.NODE_ENV === 'chunin') {
		REST_API_URL = 'https://api-chunin.tacticalao.com/api';
	} else if (process.env.NODE_ENV === 'genin') {
		REST_API_URL = 'https://api-genin.tacticalao.com/api';
	} else {
		REST_API_URL = 'http://0.0.0.0:8000/api';
	}
	console.debug(`---> REST_API_URL = ${REST_API_URL}`);
	return REST_API_URL;
}

/**
 * Build fully-qualified URLs for the REST API
 * @param relativeURL
 * @returns {string}
 */
function buildFullyQualifiedRestApiURL(relativeURL) {
	return getRestApiUrl() + relativeURL;
}

// fully-qualfied URLS for REST API
export const RestApiURLs = {
	base: getRestApiUrl(),
	authToken: buildFullyQualifiedRestApiURL('/token/'),
	refreshAuthToken: buildFullyQualifiedRestApiURL('/token/refresh/'),
};


/**
 * Build the BASE URL for the React web-site
 *
 * @returns {string}
 */
function getReactUrl() {
	console.debug(`---> process.env.NODE_ENV = ${process.env.NODE_ENV}`);
	let REACT_URL;
	if (process.env.NODE_ENV === 'jonin') {
		REACT_URL = 'https://tacticalao.com';
	} else if (process.env.NODE_ENV === 'chunin') {
		REACT_URL = 'https://chunin.tacticalao.com';
	} else if (process.env.NODE_ENV === 'genin') {
		REACT_URL = 'https://genin.tacticalao.com';
	} else {
		REACT_URL = 'http://localhost:3000';
	}
	console.debug(`---> REACT_URL = ${REACT_URL}`);
	return REACT_URL;
}

/**
 * Build fully-qualified URLs for the REST API
 * @param relativeURL
 * @returns {string}
 */
function buildRelativeReactURLs(relativeURL) {
	return getReactUrl() + relativeURL;
}

const REACT_HOME_URL = buildRelativeReactURLs('/');
/**
 * Fully-Qualified URLs to the React web-site.
 */
export const ReactURLs = {
	// Home and other key URLs
	home: '/',
	resupplyStore: 'https://resupply.tacticalao.com/',
	aboutUs: '/about',
	curencies: '/currencies',
	hrefBitcoin: 'bitcoin',
	hrefEthereum: 'ethereum',
	hrefUSDollars: 'usdollars',
	referralsRewards: '/referral-rewards',
	support: "/support",
	privacy: "/privacy",
	deletingYourAccount: "/delete-your-account",
	termsAndConditions: "/terms-of-use",
	status: "/status",

	// Join Beta Test program
	googleBetaTesters: "/tacao-beta-testers",

	// Additional URLs (release date: TBD)
	airdrop: "/airdrop",
	faq: "/faq",
	news: "/news",
	newsLetter: "/news-letter",

	// Crypto Ninja application URLs (release date: TBD)
	receivePayment: "/recieve-payment",
	myMoney: "/my-money",
	myAccount: "/my-account",

	// Login, Sign Up, Logout URLs (release date: TBD)
	login: "/login",
	signup: "/signup",
	logout: "/logout",

	// External URLs
	appStoreApple: "https://apps.apple.com/us/app/tacao/id6538715116",
	appStoreGoogle: "/tacao-beta-testers",

	// social media URLs
	youtube: "https://www.youtube.com/@TacticalAO",
	facebook: "https://www.facebook.com/TacticalAO",
	rumble: "https://rumble.com/user/TacticalAO",
	twitter: "https://x.com/TacticalAO",
};





