import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoWithWordMarkImage from "../assets/img/logo-with-wordmark/logo-with-wordmark.png";
import { bindActionCreators } from "redux";
import { updatePageTitle } from "../reducers/page";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { ReactURLs } from "../globals/urls";
import AppleAppStore from "./images/AppleAppStore";
import GooglePlayStore from "./images/GooglePlayStore";


class NavBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container
                fluid
                className="m-0 p-0"
                style={{overflowX: "hidden"}}
            >
                <Navbar
                    collapseOnSelect
                    bg="light"
                    variant="light"
                    fixed="top"
                    sticky="top"
                    expand="sm"
                    className="m-0 p-0 py-1 py-sm-1 py-md-3 px-2 px-sm-2 px-md-4"
                >
                    <Navbar.Brand
                        href={ReactURLs.home}
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-duration="2250"
                        // data-aos-delay="500"
                        data-aos-anchor-placement="top-bottom"
                    >
                        <Image
                            style={{
                                maxWidth: '100px',
                            }}
                            src={LogoWithWordMarkImage}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle/>

                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Link href={ReactURLs.home} style={styles.navlink}>
                                Home
                            </Nav.Link>
                            <Nav.Link href={ReactURLs.resupplyStore} style={styles.navlink}>
                                Resupply Store
                            </Nav.Link>
                            <Nav.Link href={ReactURLs.appStoreApple} style={styles.navlink}>
                                <AppleAppStore/>
                            </Nav.Link>
                            <Nav.Link href={ReactURLs.appStoreGoogle} style={styles.navlink}>
                                <GooglePlayStore/>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </Container>
        );
    }
}


const styles = {
  navlink: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
  }
};

const mapStateToProps = (state) => {
    const { page } = state;
    return { page };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updatePageTitle,
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

