import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CNBlue } from "./SiteColors";
import { ReactURLs } from "../../globals/urls";
import rumbleIcon from "../../assets/img/icons/social_media/RumbleIcon.svg";


class SocialMediaLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {...props};
    }

    render() {
        return (
            <div
                className={this.state.className}
                style={this.state.style}
            >
                <a
                    href={ReactURLs.facebook}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={["fab", "facebook-f"]}
                        size="lg"
                        style={{
                            ...styles.icon,
                            ...styles.facebook,
                        }}
                    />
                </a>

                <a
                    href={ReactURLs.rumble}
                    target="_blank"
                >
                    <img
                        src={rumbleIcon}
                        style={{
                            ...styles.icon,
                            width: 20,
                            color: 'red',
                            fill: 'red',
                        }}
                    />
                </a>

                {/*<a href="https://twitter.com/CybertronNinja">*/}
                {/*    <FontAwesomeIcon*/}
                {/*        icon={["fab", "twitter"]}*/}
                {/*        size="lg"*/}
                {/*        style={styles.icon}*/}
                {/*    />*/}
                {/*</a>*/}

                {/*<a href="https://www.linkedin.com/company/cybertronninja">*/}
                {/*    <FontAwesomeIcon*/}
                {/*        icon={["fab", "linkedin-in"]}*/}
                {/*        size="lg"*/}
                {/*        style={styles.icon}*/}
                {/*    />*/}
                {/*</a>*/}

                <a
                    href={ReactURLs.youtube}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={["fab", "youtube"]}
                        size="lg"
                        style={{
                            ...styles.icon,
                            ...styles.youtube,
                        }}
                    />
                </a>

                <a
                    href={ReactURLs.twitter}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={["fab", "twitter"]}
                        size="lg"
                        style={{
                            ...styles.icon,
                            ...styles.facebook,
                        }}
                    />
                </a>
            </div>
        );
    }
}


SocialMediaLinks.defaultProps = {
    style: {
        color: 'black',
        backgroundColor: 'white',
    },
    className: "m-0 p-0",
};

const styles = {
    icon: {
        color: CNBlue,
        marginLeft: 5,
        marginRight: 5
    },
    facebook: {
        color: '#1877F2',
    },
    youtube: {
        color: '#FF0000',
    },
};


export default SocialMediaLinks;

