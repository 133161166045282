import React, { Component } from "react";
import Content from "../Components/Content/Content";
import { H3, P } from "../Components/Content/Content";
import { ReactURLs } from "../globals/urls";
import { CNBlue } from "../Components/Content/SiteColors";


class ReferralProgram extends Component {
	render() {
		return (
			<div>
				<Content>
					<H3>Referral Rewards</H3>

					<P>
						We've done the heavy lifting, so you can get paid while you sleep. Just share
						Crypto Ninja with business owners and you'll earn 2% commission on all
						crypto-currency payments they process using our app.
					</P>

					<P>
						You can earn residual income in three easy steps...
						<ol>
							<li>Create an account in the Crypto Ninja app and verify your identity</li>
							<li>Share your referral code with business owners</li>
							<li>
								Once they sign up for a new account, you will automatically be paid in US dollars*.
							</li>
						</ol>
					</P>
					<p style={{
						...styles.termsAndConditions,
						color: CNBlue,
					}}>
						* Subject to Terms of Use.<br/>
						By sharing your referral code, you agree to
						our <a href={ReactURLs.termsAndConditions}>Terms of Use</a> and <a href={ReactURLs.privacy}>Privacy Policy</a>.
					</p>

				</Content>
			</div>
		);
	}
}

const styles = {
	termsAndConditions: {
		paddingTop: 15,
		paddingBottom: 15,
		fontSize: 12,
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
	},
};


export default ReferralProgram;
